<template>
  <a-layout :class="{ closed: !openStatus, bordered: bordered }">
    <a-layout-header>
      <span>
        <span style="margin-right: 10px">{{ title }}</span>
        <a-icon
          v-if="foldBtnPos === 'left'"
          class="arrow color-icon"
          :class="openStatus !== null ? (openStatus ? 'open' : 'close') : ''"
          @click="clickHandle"
          type="double-right"
        ></a-icon>
      </span>
      <span v-show="openStatus">
        <slot name="extra"></slot>
      </span>
      <span
        class="right-btn"
        @click="clickHandle"
        v-show="foldBtnPos === 'right' && !openStatus"
        ><a-icon
          class="arrow color-icon"
          :class="openStatus !== null ? (openStatus ? 'open' : 'close') : ''"
          type="double-right"
        ></a-icon
        ><span>展开</span></span
      >
    </a-layout-header>
    <transition name="fold-content">
      <a-layout-content v-show="openStatus">
        <slot></slot>
      </a-layout-content>
    </transition>
    <div class="footer-btn-box" v-show="foldBtnPos === 'right' && openStatus">
      <span class="right-btn" @click="clickHandle"
        ><a-icon
          class="arrow color-icon"
          :class="openStatus !== null ? (openStatus ? 'open' : 'close') : ''"
          type="double-right"
        ></a-icon
        ><span>收起</span></span
      >
    </div>
  </a-layout>
</template>

<script>
export default {
  name: "FoldCard",
  props: {
    title: {
      type: String,
      default: "",
    },
    open: {
      type: Boolean,
      default: true,
    },
    bordered: {
      type: Boolean,
      default: true,
    },
    // 用于缓存状态折叠面板的状态
    cardKey: {
      type: String,
      default: "",
    },
    foldBtnPos: {
      type: String,
      default: "left",
    },
  },
  data() {
    return {
      openStatus: null,
      returnFlag: false,
    };
  },
  created() {
    // 检测是否是返回
    if (sessionStorage.getItem("RETURN_FLAG")) {
      if (
        sessionStorage.getItem("RETURN_FLAG") === "true" &&
        sessionStorage.getItem(`FOLD_${this.cardKey}`)
      ) {
        // 是返回，且已存储折叠面板状态
        if (sessionStorage.getItem(`FOLD_${this.cardKey}`) === "true") {
          this.openStatus = true;
        } else {
          this.openStatus = false;
        }
      } else {
        this.openStatus = this.open;
      }
    } else {
      this.openStatus = this.open;
    }
  },
  beforeDestroy() {
    sessionStorage.setItem("RETURN_FLAG", false);
  },
  methods: {
    clickHandle() {
      if (this.openStatus === null) {
        this.openStatus = !this.open;
      } else {
        this.openStatus = !this.openStatus;
      }
      sessionStorage.setItem(`FOLD_${this.cardKey}`, this.openStatus);
    },
  },
};
</script>

<style lang="less" scoped>
.ant-layout {
  margin-bottom: 16px;
  background: #fff;
  &.bordered {
    border: 1px solid #e8e8e8;
  }
  &.closed {
    border-bottom: none;
  }
}
.ant-layout .ant-layout-header {
  display: flex;
  justify-content: space-between;
  min-height: 48px;
  padding: 0 24px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  background: transparent;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 2px 2px 0 0;
  zoom: 1;
  height: 56px;
}
.ant-layout .ant-layout-content {
  zoom: 1;
  padding: 12px 24px 24px;
  &.open {
    animation: icon-open 0.3s linear;
    animation-fill-mode: forwards;
  }
  &.close {
    animation: icon-close 0.3s linear;
    animation-fill-mode: forwards;
  }
}
.arrow {
  transform: rotate(450deg);
  &.open {
    animation: icon-open 0.3s linear;
    animation-fill-mode: forwards;
  }
  &.close {
    animation: icon-close 0.3s linear;
    animation-fill-mode: forwards;
  }
}
@keyframes icon-open {
  0% {
    transform: rotate(450deg);
  }
  50% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
@keyframes icon-close {
  0% {
    transform: rotate(270deg);
  }
  50% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(450deg);
  }
}

.fold-content-enter-active,
.fold-content-leave-active {
  transition: all 0.3s ease;
  max-height: 1000px;
}
.fold-content-enter,
.fold-content-leave-to {
  opacity: 0;
  max-height: 0;
}

.footer-btn-box {
  text-align: right;
  padding-right: 10px;
  padding-bottom: 10px;
}
.right-btn {
  color: #888;
  cursor: pointer;
  .anticon {
    margin-right: 5px;
    font-size: 14px;
  }
  span {
    font-size: 14px;
  }
}
</style>