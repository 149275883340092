<template>
  <a-row class="condition-row" type="flex" justify="space-between">
    <a-col :span="9">
      <a-select
        mode="multiple"
        placeholder="请选择筛选列"
        :value="selectedItems"
        style="width: 100%"
        @change="hanldeColumnChange"
      >
        <a-select-option
          v-for="item in filterColumns"
          :key="item"
          :value="item"
        >
          {{ item }}
        </a-select-option>
      </a-select>
    </a-col>
    <a-col :span="4">
      <a-radio-group
        :value="rowData.condition"
        @change="handleConditionChange"
        style="min-width: 133px"
      >
        <a-radio-button value="CONTAIN"> 包含 </a-radio-button>
        <a-radio-button value="NOT_CONTAIN">不包含</a-radio-button>
      </a-radio-group>
    </a-col>
    <a-col :span="9">
      <a-tag
        v-for="(keyword, index) in rowData.keywordList"
        :key="index"
        :closable="false"
        @close="handleClose(keyword)"
      >
        {{ keyword
        }}<a-icon type="close" @click="handleClose(keyword)"></a-icon>
      </a-tag>
      <a-input
        v-if="inputVisible"
        ref="input"
        type="text"
        size="small"
        :style="{ width: '78px' }"
        :value="inputValue"
        @change="handleInputChange"
        @blur="handleInputConfirm"
        @keyup.enter="handleInputConfirm"
      />
      <a-tag
        v-else
        style="background: #fff; border-style: dashed"
        @click="showInput"
      >
        <a-icon type="plus" /> 添加关键词
      </a-tag>
    </a-col>
  </a-row>
</template>

<script>
export default {
  props: {
    columnData: {
      type: Array,
    },
    rowData: {
      type: Object,
      default: null,
    },
    change: {
      type: Boolean,
    },
  },
  data() {
    return {
      inputVisible: false,
      inputValue: "",
      selectedItems: this.rowData.columnList,
    };
  },
  computed: {
    filterColumns() {
      return this.columnData.filter(
        (o) => !this.rowData.column.split(",").includes(o)
      );
    },
  },
  methods: {
    handleClose(removedTag) {
      setTimeout(() => {
        const keywordList = this.rowData.keywordList.filter(
          (tag) => tag !== removedTag
        );
        this.rowData.keywordList = keywordList;
        this.$emit("update:change", true);
        this.$forceUpdate();
      }, 10);
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(function () {
        this.$refs.input.focus();
      });
    },
    handleInputChange(e) {
      this.inputValue = e.target.value;
    },
    hanldeColumnChange(selectedItems) {
      this.selectedItems = selectedItems;
      this.rowData.columnList = selectedItems;
      this.$emit("update:change", true);
    },
    handleConditionChange(e) {
      this.rowData.condition = e.target.value;
      this.$emit("update:change", true);
    },
    handleInputConfirm() {
      const inputValue = this.inputValue;
      this.inputVisible = false;
      if (inputValue === "") return;
      let tags = this.rowData.keywordList;
      if (inputValue && tags.indexOf(inputValue) === -1) {
        tags = [...tags, inputValue];
      }
      this.rowData.keywordList.push(inputValue);
      Object.assign(this, {
        inputValue: "",
      });
      this.$emit("update:change", true);
    },
  },
};
</script>

<style scoped lang="less">
.condition-row {
  padding: 10px 0;
}
.ant-tag {
  margin: 5px 5px 0 0;
}
</style>