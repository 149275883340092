<template>
  <div>
    <div class="content">
      <div class="title">{{ labelName }}</div>
      <fold-card
        :title="conditionIndex > -1 ? '条件' + conditionIndex : '新增条件'"
        foldBtnPos="right"
        class="list-box"
      >
        <a-row class="list-title" style="text-align: center">
          <a-col :span="3"></a-col>
          <a-col :span="8">筛选列</a-col>
          <a-col :span="4">筛选关系</a-col>
          <a-col :span="4">筛选关键词</a-col>
        </a-row>
        <div v-if="columnData && conditionData">
          <a-row v-for="(item, index) in conditionData" :key="index">
            <a-col :span="3">
              <a-popconfirm
                title="确定移除该子条件吗？"
                ok-text="是"
                cancel-text="否"
                @confirm="handleDelete(item, index)"
              >
                <a-icon slot="icon" type="close-circle" style="color: red" />
                <a style="line-height: 55px"
                  ><a-icon type="minus-circle" style="fontsize: 20px"></a-icon
                ></a>
              </a-popconfirm>
              子条件{{ index + 1 }}：
            </a-col>
            <a-col :span="21">
              <condition-form-row
                :columnData="columnData"
                :rowData="item"
                :change.sync="change"
              ></condition-form-row>
            </a-col>
          </a-row>
        </div>

        <a-button
          style="width: 160px; margin: 10px 0 20px"
          @click="handleAddSubCondition()"
          type="primary"
          ><a-icon type="plus" style="font-size: 12px"></a-icon
          >添加筛选子条件</a-button
        >
        <div class="btn-box">
          <a-button
            size="large"
            :type="change ? 'primary' : ''"
            @click="handleSave()"
            >保存筛选条件</a-button
          ><a-button
            @click="handleSearch()"
            :type="change ? '' : 'primary'"
            size="large"
            :disabled="change"
            title="使用所有子条件进行筛选"
            >预览筛选结果</a-button
          >
        </div>
      </fold-card>
      <product-table
        ref="productTable"
        v-if="tableShow"
        :labelId="labelId"
        :groupId="groupId"
      ></product-table>
      <div v-for="(item, index) in columnData" :key="index">
        {{ item.keyword }}
      </div>
    </div>
  </div>
</template>

<script>
import ConditionFormRow from "./components/CondetionFormRow";
import ProductTable from "./components/ProductTable";
import FoldCard from "@/components/FoldCard";

import storage from "store";
import {
  getFilterColumnList,
  addLabelFilter,
  updateLabelFilter,
  deleteLabelFilter,
} from "@/api/tag";

export default {
  name: "TaskForm",
  components: {
    ConditionFormRow,
    ProductTable,
    FoldCard,
  },
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 },
      },
      form: this.$form.createForm(this),
      isSpinning: false,
      selectedItems: [],
      conditionData: null,
      columnData: null,
      tableShow: false,
      groupId: null,
      visible: false,
      record: null,
      change: false, // 子条件数据是否发生改变
      labelId: parseInt(this.$route.query.labelId),
      labelName: this.$route.query.labelName,
      conditionIndex: this.$route.query.index,
    };
  },
  created() {
    this.initConditionData();
    this.loadData();
  },
  watch: {
    visible() {
      // 弹窗关闭时重置父组件的当前列
      if (!this.visible) this.$parent.resetCurColumnIndex();
    },
  },
  methods: {
    loadData() {
      getFilterColumnList({ labelId: this.labelId }).then((res) => {
        if (res.code === 0) {
          this.columnData = res.data;
        }
      });
    },
    initConditionData() {
      let info = storage.get("CONDITION_INFO");
      if (info) {
        this.groupId = parseInt(info.groupId);
        let data = info.conditionData;
        let newData = [];
        data.forEach((item) => {
          if (item.keyword.length > 0) {
            item.keywordList = item.keyword.split(",");
          } else {
            item.keywordList = [];
          }
          if (item.column.length > 0) {
            item.columnList = item.column.split(",");
          } else {
            item.columnList = [];
          }
          newData.push(item);
        });
        this.conditionData = newData;
      } else {
        this.groupId = -1;
        this.conditionData = [];
      }
    },
    handleAddSubCondition() {
      this.conditionData.push({
        column: "",
        condition: "CONTAIN",
        groupId: this.groupId,
        keywordList: [],
        columnList: [],
        labelId: this.labelId || null,
      });
      this.change = true;
    },
    setChange() {
      this.change = true;
    },
    handleSearch() {
      if (!this.tableShow) {
        this.tableShow = true;
      } else {
        this.$refs.productTable.fetchData(1);
      }
    },
    edit(record) {
      this.record = record || null;
      this.groupId = record ? parseInt(record.groupId) : -1;
      this.conditionData = [];
      setTimeout(() => {
        this.initConditionData();
        this.tableShow = false;
        this.visible = true;
      });
    },
    handleSave() {
      let formData = [];
      let flag = true;

      this.conditionData.map((item) => {
        if (item.columnList.length <= 0) {
          this.$message.error("筛选列不能为空");
          flag = false;
          return;
        }
        if (item.keywordList.length <= 0) {
          this.$message.error("筛选关键词不能为空");
          flag = false;
          return;
        }
        let newItem = {
          column: item.columnList.join(","),
          condition: item.condition,
          keyword: item.keywordList.join(","),
          labelId: this.labelId,
        };
        if (this.groupId !== -1) {
          newItem.id = item.id || null;
          newItem.groupId = this.groupId;
        }
        formData.push(newItem);
      });
      if (flag) {
        if (this.groupId !== -1) {
          updateLabelFilter(formData).then((res) => {
            if (res.code === 0) {
              this.updateCondition(res.data);
              this.$message.success("更新成功");
              this.change = false;
            }
          });
        } else {
          addLabelFilter(formData).then((res) => {
            if (res.code === 0) {
              this.groupId = parseInt(res.data[0].groupId);
              this.updateCondition(res.data);
              this.$message.success("添加成功");
              this.change = false;
            }
          });
        }
      }
    },
    updateCondition(data) {
      this.conditionData.forEach((item, index) => {
        if (!item.id) {
          this.conditionData[index].id = data[index].id;
        }
      });
    },
    handleDelete(record, index) {
      if (record.id) {
        deleteLabelFilter({ id: record.id }).then((res) => {
          if (res.code == 0) {
            this.conditionData.splice(index, 1);
            this.$message.success("删除成功");
            this.$parent.loadData();
          }
        });
      } else {
        this.conditionData.splice(index, 1);
      }
    },
    handleClose() {
      this.loadData();
    },
  },
};
</script>

<style scoped lang="less">
.content {
  padding-left: 20px;
}
.title {
  font-size: 26px;
  padding: 12px 0;
  color: #000;
  font-weight: bold;
}
.list-title .ant-col {
  text-align: center;
}

.btn-box {
  width: fit-content;
  margin: 20px auto;
  .ant-btn {
    width: 200px;
    &:last-child {
      margin-left: 150px;
    }
  }
}
</style>