import request from "@/utils/request";

const productApi = {
  GetProductList: "/product/search",
  GetProductByLabelFilter: "/product/find-by-label-filter",
  GetProductInfo: "/product/info",
  GetProductInfoByCompanyId: "/company/products",
  AddProduct: "/product/add",
  CopyProduct:'/product/copy',
  UpdateProduct: "/product/edit",
  DeleteProduct: "/product/delete",
  GetNewsList: "/product/news",

  UpdateProductFromQMP:'/data/product/update'
};

export function getProductList(parameter) {
  return request({
    url: productApi.GetProductList,
    method: "get",
    params: parameter,
  });
}

export function getProductByLabelFilter(parameter) {
  return request({
    url: productApi.GetProductByLabelFilter,
    method: "get",
    params: parameter,
  });
}

export function getProductInfo(parameter) {
  return request({
    url: productApi.GetProductInfo + `/${parameter.productId}`,
    method: "get",
  });
}

export function getProductInfoByCompanyId(parameter) {
  return request({
    url: productApi.GetProductInfoByCompanyId + `/${parameter.companyId}`,
    method: "get",
  });
}

export function addProduct(parameter) {
  return request({
    url: productApi.AddProduct,
    method: "post",
    data: parameter,
  });
}

export function copyProduct(parameter) {
  return request({
    url: productApi.CopyProduct,
    method: 'post',
    data: parameter
  })
}

export function updateProduct(parameter) {
  return request({
    url: productApi.UpdateProduct,
    method: "post",
    data: parameter,
  });
}

export function deleteProduct(parameter) {
  return request({
    url: productApi.DeleteProduct + `/${parameter.id}`,
    method: "post",
  });
}

export function getNewsList(parameter) {
  return request({
    url: productApi.GetNewsList + `/${parameter.productId}`,
    method: "get",
    params: parameter.page,
  });
}

export function updateProductFromQMP(parameter) {
  return request({
      url: productApi.UpdateProductFromQMP + `/${parameter.productId}`,
      method: 'post'
  })
}