<template>
  <a-table
    :columns="columns"
    :data-source="data"
    :loading="loading"
    rowKey="id"
    :pagination="pagination"
    @change="handleTableChange"
  >
  </a-table>
</template>    

<script>
import { getProductByLabelFilter } from "@/api/product";
const columns = [
  {
    title: "项目名称",
    dataIndex: "name",
  },
  {
    title: "简介",
    dataIndex: "introduction",
    width: "20em",
  },
  {
    title: "业务",
    dataIndex: "business",
  },
  {
    title: "行业",
    dataIndex: "sector",
  },
  {
    title: "细分行业",
    dataIndex: "sub_sector",
  },
  {
    title: "其他行业标签",
    dataIndex: "labels",
  },
  {
    title: "其他行业分类",
    dataIndex: "sub_industry",
  },
];
export default {
  data() {
    return {
      data: [],
      pagination: {},
      loading: false,
      columns,
    };
  },
  props: {
    labelId: {
      type: Number,
      default: -1,
    },
    groupId: {
      type: Number,
      default: -1,
    },
  },
  created() {
    this.fetchData(1);
  },
  methods: {
    handleTableChange(pagination) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      this.fetchData(pagination.current);
    },
    fetchData(page) {
      this.loading = true;
      if (page > -1) {
        this.pagination.current = page;
      }
      getProductByLabelFilter({
        labelId: this.labelId,
        groupId: this.groupId,
        page: page - 1 || 0,
      }).then((res) => {
        if (res.code === 0) {
          let data = res.data;
          const pagination = { ...this.pagination };
          pagination.total = data.totalElements;
          this.loading = false;
          this.data = data.content;
          this.pagination = pagination;
        }
      });
    },
  },
};
</script>